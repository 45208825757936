import axios from 'axios';

export const getConfigParams = async ({ timestamp, nonce_str, url }) => {
  const { data } = await axios.post('https://kfccdn.xiuyan.info/dyapi/signature', {
	  timestamp,
	  nonce_str, 
	  url
  })
  const { client_key, signature } = data.data
  return { client_key, signature };
};

export const getOpenid = async ({ code, user_id }) => {
  await axios.post('https://kfccdn.xiuyan.info/dyapi/authorize', {
	  code,
	  user_id
  })
  return true
}