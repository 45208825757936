import React, { useState, useEffect, useCallback } from 'react';
import { getConfigParams, getOpenid } from './server';
import logoBig from './images/logo_cancan.png';  // 导入本地图片
import shuangjiantou from './images/shuangjiantou.png';
import douyinBig from './images/douyin_big.png';

const { location } = window;
const url = location.href;


const timestamp = String(parseInt(Date.now() / 1000)); // 生成签名用的时间戳
const nonce_str = 'Wm3WZYTPz0wzccnW'; // 生成签名用的随机字符串
const jsb = ''; // 自定义测试用JSB方法名
const params = {}; // 自定义测试用JSB方法参数

const getURLParams = (url) => {
  // 解析参数部分
  var paramsString = url.split('?')[1];
  
  if (!paramsString) {
      return {};
  }

  // 将参数字符串分割成键值对数组
  var paramsArray = paramsString.split('&');
  
  // 创建一个空对象，用于存储参数
  var paramsObject = {};

  // 遍历键值对数组，将其转换为对象
  paramsArray.forEach(function(param) {
      var keyValue = param.split('=');
      var key = decodeURIComponent(keyValue[0]);
      var value = decodeURIComponent(keyValue[1] || '');
      paramsObject[key] = value;
  });

  return paramsObject;
}

const { code } = getURLParams(url)

export default () => {
  const [sdk, setSdk] = useState(null);
  const [ready, setReady] = useState(false);
  const [configStatus, setConfigStatus] = useState('');
  const [resultMsg, setResultMsg] = useState('');
  const [client_key, setClientKey] = useState('');

  // 打开原生授权页面JSB能力示例
  const onClickAuth = () => {
    if (ready) {
      sdk.showOpenAuth({
        params: {
          client_key,
          state: '',
          scopes: {
            user_info: 0, // 0: 必选；1: 可选，默认不选中； 2: 可选，默认选中
			// 'trial.whitelist': 0,
			// 'video.data.bind': 0,
			'data.external.user': 0,
			'data.external.item': 0,
            'video.search': 0,
      // 'video.create.bind': 0,
		    // 'video.list.bind': 0
			// enterprise_info: 2
			// item_permissions: 'video'
          },
		  // scope: 'user_info,trial.whitelist',
		  //trial: {
			//whitelist: true,
		  //},
          response_type: 'code'
        },
        success: ({ ticket }) => {
          // setResultMsg(`Auth Success: ${ticket}`)

          // 获取信息
          login(ticket, code);
        },
        error: res => setResultMsg(`Auth Error: ${JSON.stringify(res)}`)
      });
    }
  };
  const onClickCloseAuth = () => {
alert("请关闭页面！")

	  // location.close();
    
  };

  // 可在URL参数中自定义JSB方法名和参数以测试
  const onClickJSB = () => {
    if (ready) {
      sdk[jsb]({
        params,
        success: res => setResultMsg(`JSB ${jsb} Success: ${JSON.stringify(res)}`),
        error: res => setResultMsg(`JSB ${jsb} Error: ${JSON.stringify(res)}`)
      });
    }
  };


  // 使用JSB能力前，必须先通过Config验证签名
  const config = useCallback(async () => {
    const { client_key, signature } = await getConfigParams({ timestamp, nonce_str, url });
    sdk.config({
      params: {
        client_key,
        signature,
        timestamp,
        nonce_str,
        url
      }
    });
    setClientKey(client_key);
  }, [sdk]);

  const login = async (code, user_id) => {
    await getOpenid({ code, user_id })
	alert("授权完成，请关闭页面！")
	// alert("1111")
	// window.history.go(-1);
	// window.close();
  }

  useEffect(() => {
	 
    setSdk(window.douyin_open);
    if (sdk) {
      setConfigStatus('SDK Loaded');
      config();
      sdk.ready(() => {
        setReady(true);
        if(ready) onClickAuth();
        setConfigStatus('SDK Config Ready');
      });
      sdk.error(res => {
        setConfigStatus(`SDK Config Error: ${JSON.stringify(res)}`);
      });
    }
  }, [sdk, config]);



  return (
    <>
      {/* <p onClick={() => location.reload()} className='link'>{'Reload'}</p> */}
      {/* <p>{configStatus} </p> */}
	  <div className='container'>
	      <div className='header-title'>参参申请授权绑定</div>
	      <div className='header-title-two'>申请将您的抖音账号数据</div>
	      <div className='header-img'>
	          <img className='header-img-one' src={logoBig}/>
	          <img className='header-img-two' src={shuangjiantou}/>
	          <img className='header-img-three' src={douyinBig}/>
	      </div>
	      <div className='header-content'>
	         <div className='header-content-title'>授权能力 <text className='header-content-text'>视频数据，抖音账号个人数据</text></div>
	          <div className='header-content-list'><div className='header-content-list-title'>绑定须知</div> <div className='header-content-list-content'>
	              <div>1.获取你发布的抖音视频数据（播放数点赞数、评论数、分享数）</div>
	              <div>2.抖音账号个人数据（公开作品数、点赞数、评论粉丝数、主页访问数、数、分享数、转发数）</div>
	    
	          </div>
	          </div>
	      </div>
	      <div className='header-radio'>
	  

	  
	  
	  
	  
	  
	      </div>
	  
	      <div className='header-bottom'>
	          <div onClick={onClickCloseAuth} className='header-bottom-no'>拒绝</div>
	          <div onClick={onClickAuth} className='header-bottom-yes'>同意</div>
	      </div>
	  </div>
	


    </>
  );
};

const flexRow = {
  display: 'flex',
  marginBottom: '50px'
}

const margin20 = {
  marginLeft: '20px',
  marginRight: '20px'
}

const logoImg = {
  width: '50px',
  height: '50px'
}
